import $ from "cash-dom";
export function initCustomerLogoFader(options) {
  const logoWrapperFirstSelector = ".logo-wrapper:nth-child(1)";
  const delay = options.delay;
  const waitingTime = options.waitingTime;
  // $('.logo-wrapper img').css('transition')


  function swapClasses(selector) {
    const logoWrapperEl = $(selector);
    const orgVisibleEl = $(logoWrapperEl).find('.logo--visible');
    const orgInvisibleEl = $(logoWrapperEl).find('.logo--invisible');
    const nextLogoWrapperEl = logoWrapperEl.next();

    orgVisibleEl.removeClass('logo--visible').addClass('logo--invisible');
    orgInvisibleEl.removeClass('logo--invisible').addClass('logo--visible');
    // debugger;
    // console.log(nextLogoWrapperEl);
    if(nextLogoWrapperEl.length !== 0) {
      window.setTimeout(() => {
        swapClasses(nextLogoWrapperEl);
      }, delay);
    } else {
      window.setTimeout(() => {
        swapClasses(logoWrapperFirstSelector);
      }, waitingTime);
    }
    
  } // END swapClasses()


  window.setTimeout(()=> {
    swapClasses(logoWrapperFirstSelector);
  }, waitingTime);
  

} // END initCustomerLogoFader()