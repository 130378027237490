import $ from "cash-dom";

function onElementHeightChange(elm, callback){
	var lastHeight = elm.clientHeight, newHeight;
	(function run(){
		newHeight = elm.clientHeight;
		if( lastHeight != newHeight )
			callback(newHeight)
		lastHeight = newHeight
    // debugger;
        if( elm.onElementHeightChangeTimer )
          clearTimeout(elm.onElementHeightChangeTimer)

    elm.onElementHeightChangeTimer = setTimeout(run, 200);
    console.log('id in function: ', elm.onElementHeightChangeTimer);
  })();
  return elm.onElementHeightChangeTimer;
  
}

function initDomChangeListener(firstLoad) {
  let loadedTimeOut = null;
  const widgetEl = document.querySelector('.widget');
  widgetEl.addEventListener('DOMNodeInserted',(e) => {
    clearTimeout(loadedTimeOut);

    loadedTimeOut = setTimeout(()=>{
      // console.log('widget loaded!', e);
      rellax.refresh();
      $('.widget__wrapper').removeClass('widget-loading');
      if(firstLoad) {
        $('.section--projects').removeClass('loading-height');
      }
    }, 200);
    
  }, false);
}

export default function initInstaWidget() {
  const firstLoad = true;
  const hashToIframeurl = {
    CWprojects: 'elfsight-app-92a23e95-4094-475e-93f4-26d866c7e888', // all
    CWculture: 'elfsight-app-551abafc-232a-49b7-98c4-8eb81d1c5e0f',
    CWdigitalisation: 'elfsight-app-7afa4129-57e4-47ee-b2b7-ec1d76b76943',
    CWlifestyle: 'elfsight-app-e141ae83-ac47-4ad7-97bb-f575067c5de8',
    CWsustainability: 'elfsight-app-83adc303-6003-40f9-81ea-71b2153f9448',
    CWmotorsports: 'elfsight-app-8a19ba59-ab09-407e-9604-b23c1f417ca7',
    CWautomotive: 'elfsight-app-c81e35e8-d4b7-411d-930d-967f8923c728',
  }

  const hashesArr = [
    'CWprojects',
    'CWautomotive', 
    'CWmotorsports', 
    'CWsustainability', 
    'CWlifestyle', 
    'CWdigitalisation',
    'CWculture'
  ]

  const instaFilterButtons = $('.text--insta-filter > li');

  instaFilterButtons.on('click', function() {
  
    // alternativeFiltering(this);
    // return false;
    // console.log($(this));
    // console.log($(this).attr('data-button-val'));
    
    const thisEl = $(this);
    const hashName =  thisEl.attr('data-button-val');
    const nextWidgetEl = $(`.${hashName}`);
    const activeWidgetEl = $('.widget--active');

    nextWidgetEl.toggleClass('widget--active');
    activeWidgetEl.toggleClass('widget--active');
    // debugger;
    // const snapPreloaderEl = $('.widget--preloader');


    // $('.widget__wrapper').addClass('widget-loading');
    instaFilterButtons.removeClass('active-insta-filter');
    thisEl.addClass('active-insta-filter');
    rellax.refresh();
    return;
   
    // debugger;
  }); //End on.Click


  initDomChangeListener(firstLoad)
} // End initInstaWidget


  

function reloadEAPPSWidget(widgetEl) {
  const fadeDuration = 700;
  //remove widget stuff from DOM
  widgetEl[0].classList.remove('eapps-instagram-feed');
  const widgetContainer = document.querySelector('.eapps-instagram-feed-container');
  widgetContainer.style.transition = `opacity ${fadeDuration}ms`;
  widgetContainer.style.opacity = "0";

  //remove widget window properties
  delete window.eapps;
  delete window.eappsInstagramFeed;
  delete window.EappsInstagramFeedReady;

  //remove widget scripts and styles
  const headScripts = document.querySelectorAll('head script');
  const headStyles = document.querySelectorAll('head style');
  
  window.setTimeout(()=>{
    
    headScripts.forEach(script => {
      script.parentNode.removeChild(script);
    });

    // slows down the reload process and makes the grid break
    // return;
    headStyles[0].parentNode.removeChild(headStyles[0]);
    headStyles[1].parentNode.removeChild(headStyles[1]);
  }, 2000);
  

  //add and execute widget script again
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.src = 'https://apps.elfsight.com/p/platform.js';
  widgetEl[0].style.minHeight = `${widgetEl[0].offsetHeight}px`;
  head.appendChild(script);
  
}



function preloadNextIframe(hash, hashesArr, hashToIframeurl) {
  const preloaderEl = $('.widget--preloader');
  const currentIndex = hashesArr.findIndex(el =>  el === hash );
  let nextHash = null;
  
  if(currentIndex + 1 < hashesArr.length) {
    nextHash = hashesArr[currentIndex + 1];
    console.warn('preload ', nextHash);
  } else {
    nextHash = hashesArr[0];
  }
  
  if(nextHash) {
    const currentPreloaderClass = preloaderEl[0].classList[0];
    const newClassString = preloaderEl[0].className.replace(currentPreloaderClass, hashToIframeurl[nextHash] );
  
    preloaderEl[0].className = newClassString;
    preloaderEl.attr('data-hash-val', nextHash);
    reloadEAPPSWidget(preloaderEl);
  }
  
}

// window.setTimeout(()=> {
//   // window.dispatchEvent(new Event('resize')); // recalculate position
//   rellax.refresh();
//   // console.log('reszied');
// }, 500)