import "./custom-modernizr.js";
import $ from "cash-dom";
import Rellax from "rellax";
import { initEmbla } from "./embla-slider.js";
import { BotDetector } from "./bot-detector";
import { initFader } from "./fader.js";
import { initCustomerLogoFader } from "./customer-logo-fader";
import initInstaWidget from "./instagram";
// import "objectFitPolyfill";
import unmuteImg from "../assets/images/unmute-button.png";
import muteImg from "../assets/images/mute-button.png";
import SmoothScroll from "smooth-scroll";
import LazyLoad from "vanilla-lazyload";

(function(){
  // const teaserMobile = require("../assets/video/transition-teaser_mobile.mp4");
  // const teaser = require("../assets/video/transition-teaser.mp4");
  
  let $headerVideo = null; //set in document ready...

  function isMobile() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  function isMobileFirefox(){
    var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    var is_mobile = isMobile();  
    return is_firefox && is_mobile;
  }

  function initResponsiveVideo() {
    var $headerVideo = $('.video--header');
    var fileName ="header-video.mp4"
    var fileNameMobile ="header-video.mp4"
    if ($(window).width() >= 680 ) {
      $headerVideo.append('<source src="../assets/video/' + fileName  + '" type="video/mp4"/>');
    } else {
      // $headerVideo.append('<source src="../assets/video/' + fileNameMobile  + '#t=0.1" type="video/mp4"/> ');
      $headerVideo.append('<source src="../assets/video/' + fileNameMobile  + '" type="video/mp4"/>');
      // TO DO: HIER NOCH t=0.1 hinzufügen
    }
  }
 
  //Video Api Example: https://web-crunch.com/lets-build-with-javascript-html5-video-player/

  function initVideoFunctions(){
    var video = document.querySelector('.video--header');
    // var playButton = document.querySelector('.play-button');

    function togglePlay() {
      const playState = video.paused ? 'play' : 'pause';
      video[playState](); // Call play or paused method
    }
    // playButton.addEventListener('click', togglePlay);

    // video.oncanplay = function() {
    //   console.log('can play');
    //   video.play();
    // };

    function toggleMute() {
      let $muteButton = $('.mute-button');
      $headerVideo[0].muted = !$headerVideo[0].muted;
      if($headerVideo[0].muted) {
        $muteButton[0].src = unmuteImg.src;
      } else {
        console.log(unmuteImg.src);
        $muteButton[0].src = muteImg.src;
      }
    }
    $('.mute-button, .video--header').on('click', toggleMute);

    function updatePlayButton() {
      // const playBtn = document.querySelector('.play-button');
      if(this.paused) {
        console.log('pause triggered');
      } else {
        // playBtn.classList.add('hidden');
      }
    }
    // video.addEventListener('play', updatePlayButton);
    // video.addEventListener('pause', updatePlayButton);


    // function showModalStart(){
    //   $('.modal--start').show();
    //   $headerVideo[0].style.zIndex = -1;
    // }
    // video.addEventListener('ended', showModalStart);

    function stopVideo() {
      $('.video--header')[0].pause();
      $('.video--header')[0].currentTime = 0;
    }
    // video.addEventListener('click', function(){
    //   stopVideo();
    //   // showModalStart();
    // });

  }

  function avoidMobileFirefoxHeaderJump() {
    if(isMobileFirefox()) {
      var video = $("header.page-header, .video--header");
      function resizeBackground() {
        //56px -> firefox bar
        video.height($(window).height() + 60);
      }

      // $(window).on('resize', resizeBackground);
      resizeBackground();
      // $('.header.page-header, video').css('transition', 'height 9999999s');
      return true;
    } else {
      return false;
    }
  }

  function handleModalOpening() {
    const mainEl = document.querySelector('main');
    const hash = window.location.hash;
    const mainpageNoScrolling = () => { mainEl.style.display = "none"; };
  
    $('.footer-nav a').on('click', function(event) {
      console.log('footer nac clicked');
      window.scrollTo({ top:0, left: 0});
      mainpageNoScrolling();
      $('.modal--opened').removeClass('modal--opened');
      const modalID = $(this).attr('href');
      $(modalID).addClass('modal--opened');
      document.querySelector('.modal--opened .modal-content').scrollTo({top: 0});
      // debugger;
    });

    if(hash === "#datenschutz" || hash ==="#impressum") {
      mainpageNoScrolling();
    }


    $(".closebtn").on("click", function(event){
      mainEl.style.display = "block";
      $('.modal--opened').removeClass('modal--opened');

      //scroll to top if clicked on footer menu item 
      //(otherwise anchor link leads to scroll to top)
      if(!$(this).hasClass('closebtn--start')) {
        window.scrollTo(0,document.body.scrollHeight);
      } else {
        //Info: .modal--start has no "target or anchor logic"
        // $('.modal--start').hide();

        // $headerVideo[0].play();
        // $headerVideo[0].style.zIndex = 1;
      }
    });

    /*hide main if modal is opened on initial page load*/
    // $('.modal').each(function(){
    //   if($(this).css('display') !== 'none') {
    //     mainEl.style.display = "none";
    //   }
    // })
  }

  function setHeaderHeightDynamicaly() {
    let lastHeaderHeightChange =  new Date();;
    // code partly taken from: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
     // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
     let vh = window.innerHeight * 0.01;
     // Then we set the value in the --vh custom property to the root of the document
     document.documentElement.style.setProperty('--vh', `${vh}px`);
 
     // We listen to the resize event
    window.addEventListener('resize', () => {
      //why timeDiff?
      // trigger resize only on the initial resizes that are fired by the mobile browsers
      // on the initial page load. Ignore the resize when user starts to scroll and therefore
      // the url bar or suchlike disappears. Disappearing leads to "jumping" of header since
      // the size suddenly changes 

      let startTime = new Date();
      let timeDiff = (lastHeaderHeightChange - startTime) * -1;
      // console.log(timeDiff);
      if(timeDiff< 500) {
           // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        lastHeaderHeightChange = new Date();
      }
   
    });
  }

  function initMenu() {
    const pageHeader = $('.page-header');
    // const blurElements = $('main, header');

    $('.logo--menu-button, .close-menu-button').on('click',function() {
      console.log($(this));
      pageHeader.toggleClass('has-opened-menu');
      // blurElements.toggleClass('blurred');
    });

    $('ul.menu a').on('click',()=>{
      pageHeader.toggleClass('has-opened-menu');
      // blurElements.toggleClass('blurred');
    });
  }

  function initRellax() { //parallax
    window.rellax = new Rellax('.rellax', {
      speed: -3,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
      breakpoints: [414, 769, 1024]
    });

    // Bugfix: avoid jumpoing of parallax element when resizing
    window.setTimeout(()=> {
      window.dispatchEvent(new Event('resize'));
      rellax.refresh();
    }, 500)
    

  }

  function initStickyIcon() {
    $(window).on('scroll', function(){
      // console.log(window.pageYOffset);
      const backgroundEl = $('.logo-background');
      const logoEl = $('.logo--menu-button');

      if(window.pageYOffset > 40 && !(logoEl.hasClass('logo--sticky'))) {
        logoEl.addClass('logo--sticky');
        backgroundEl.addClass('visible');
      } else if(window.pageYOffset <= 40 && logoEl.hasClass('logo--sticky')) {
        logoEl.removeClass('logo--sticky');
        backgroundEl.removeClass('visible');
      }
  });
  }
  
  function setEmails(botCheckResult) {
    if(botCheckResult.isBot) { // dont set email addresses if user is bot
      return;
    }
    	// select the element that will be replaced
    let oldEmailElements = document.querySelectorAll('.person .person__mail');
    let emailLinks = [
      'PGEgY2xhc3M9InBlcnNvbl9fbWFpbCIgaHJlZj0ibWFpbHRvOmNocmlzdGlhbkBjdy52aXNpb24iPmNocmlzdGlhbkBjdy52aXNpb248L2E=',
      'PGEgY2xhc3M9InBlcnNvbl9fbWFpbCIgaHJlZj0ibWFpbHRvOm1pY2hhZWxAY3cudmlzaW9uIj5taWNoYWVsQGN3LnZpc2lvbjwvYQ',
      'PGEgY2xhc3M9InBlcnNvbl9fbWFpbCIgaHJlZj0ibWFpbHRvOnBpZXJyZUBjdy52aXNpb24iPnBpZXJyZUBjdy52aXNpb248L2E',
      'PGEgY2xhc3M9InBlcnNvbl9fbWFpbCIgaHJlZj0ibWFpbHRvOnZlcmFAY3cudmlzaW9uIj52ZXJhQGN3LnZpc2lvbjwvYT4=',
    ]

     for(let i = 0; i < oldEmailElements.length; i++) {
      let newSpanEl = document.createElement('span');
      newSpanEl.innerHTML = atob(emailLinks[i]);
      if(oldEmailElements[i] && oldEmailElements[i].parentNode) 
        oldEmailElements[i].parentNode.replaceChild(newSpanEl, oldEmailElements[i]);
    }

    // main email
    let allMainAdresses = document.querySelectorAll('.cw-main-email');
    let mainEmailAdress = atob('PGEgaHJlZj0ibWFpbHRvOmhlbGxvQGN3LnZpc2lvbiI+aGVsbG9AY3cudmlzaW9uPC9hPg==');
  

    for(let i = 0; i < allMainAdresses.length; i++) {
      let newSpanEl = document.createElement('span');
      newSpanEl.innerHTML = mainEmailAdress;
      if(allMainAdresses[i] && allMainAdresses[i].parentNode)
        allMainAdresses[i].parentNode.replaceChild(newSpanEl, allMainAdresses[i]);
      // debugger;
    }

    let noBLablaMail = document.querySelector('.no-blabla-mail ');
    let newSpanEl = document.createElement('span');
    newSpanEl.innerHTML = atob('PGEgaHJlZj0ibWFpbHRvOm5vYmxhYmxhQGN3LnN0dWRpbyI+bm9ibGFibGFAY3cuc3R1ZGlvPC9hPg==');
    if(noBLablaMail && noBLablaMail[i].parentNode)
      noBLablaMail.parentNode.replaceChild(newSpanEl, noBLablaMail);
 
  }



  function initCustomerLogoFading() {

    function clearAllTimeOuts() {
      clearTimeout(timeOuts[0]);
      clearTimeout(timeOuts[1]);
    }
   
  
    // const duration = 5000;
    const delay = 150; // time each logo "waits" for the other
    const logoCount = 14;
    const waitingTime = 4000;
    let timeOuts = [];

    window.setTimeout(()=> {
      for(let i=0; i<=logoCount; i++) {
        window.setTimeout(() => {
          const currentLogoWrapper = $(`.customers .logo-wrapper:nth-child(${i})`);
          currentLogoWrapper.addClass('logo-wrapper--fading');
  
          pauseAndPlay(currentLogoWrapper);
  
        }, delay * i);
      }

    }, waitingTime);

    

    function pauseAndPlay(currentLogoWrapper){
      // clearAllTimeOuts();
      const currentImg = currentLogoWrapper.find('img');
      
      // pause animation
      timeOuts[0] = window.setTimeout(() => {
        currentImg.css('animation-play-state', 'paused');
      }, 1500); // ($duration / $slides)*1000 in 05-clients_awards.scss

      timeOuts[1] = window.setTimeout(() => {
        currentImg.css('animation-play-state', 'running');
        pauseAndPlay(currentLogoWrapper);
      }, waitingTime + (delay*logoCount)); // ($duration / $slides) * 2 
    }
  }

  $(document).ready(function(){
    
    $headerVideo = $('.video--header');
    //initResponsiveVideo();
    //initVideoFunctions();
    initMenu();
    initStickyIcon();
    handleModalOpening();
    // avoidMobileFirefoxHeaderJump();
    /*if(isMobile()){
      setHeaderHeightDynamicaly();
    }*/
    if(isMobile()){
      document.body.classList.add('device-is-mobile');
    }
    initRellax();
    initEmbla();
    // initFader(".header-slider > div", 4000);
    // initFader(".logo-fader > img", 2000);
    initCustomerLogoFader({ delay: 150, waitingTime: 4000 });
    initInstaWidget();
    new SmoothScroll('a[href*="#"]');
    // setEmails();
    var botDetector = new BotDetector({
      timeout: 1000,
      callback: setEmails
    });
    botDetector.monitor();

    // const lazyLoadInstance = new LazyLoad({
    //   elements_selector: ".lazy-load--iframe",
    //   threshold: 700
    //   // ... more custom settings?
    // });
  }); // end ready()


})();


// window.addEventListener('load', function () {
//   alert("It's loaded!")
// })

