import EmblaCarousel from 'embla-carousel';
import $ from "cash-dom";
import { Fancybox } from "@fancyapps/ui";

export const initEmbla = () => {
  let timeout = 0;

  if(typeof ENV !=="undefined" && ENV ==="dev") {
    timeout = 200;
  }
  window.setTimeout(() => {
    emblaSetup();
  }, timeout);

  $('.embla__slide').on('click', function(){
    /*const thisOverlay = $(this);
    if(!thisOverlay.hasClass('overlay--opened')) {
      $(this).addClass('overlay--opened');
    }
    $('.embla__nav').hide();*/
  });

  // const closingElements = 
  $('.embla__slide')
    .find('.overlay__close, .overlay__text--list, .overlay__headline').on('click', function(e){
    e.stopPropagation();
    const parentOverlay = $(this).parents('.overlay--opened');
    parentOverlay.removeClass('overlay--opened');
    $('.embla__nav').removeAttr('style');
    // $(this).children();
    // debugger;
  })
  // .children('.overlay__text--contact').on('click', function(e) {
  //   console.log ('click on email');
  //   // return false;
  // });

  var ul = document.querySelector('.embla__container');
  for (var i = ul.children.length; i >= 0; i--) {
    ul.appendChild(ul.children[Math.random() * i | 0]);
  }

  Fancybox.bind('[data-fancybox="expertise-carousel"]', {
    Carousel: {
      on: {
        change: (that) => {
          // Sync Carousel slide
          /*mainCarousel.slideTo(mainCarousel.findPageForSlide(that.page), {
            friction: 0,
          });*/
        },
      },
    },
  });
  
};

const emblaSetup = () => {

  const wrap = document.querySelector(".embla");
  const viewPort = wrap.querySelector(".embla__viewport");
  // const emblaNode = document.querySelector('.embla')
  const options = {
    loop: true
  }
  
  const embla = EmblaCarousel(viewPort, options);
  const nextButton = document.getElementsByClassName('embla__nav--next')[0];
  const prevButton = document.getElementsByClassName('embla__nav--prev')[0];
  prevButton.addEventListener("click", embla.scrollPrev, false);
  nextButton.addEventListener("click", embla.scrollNext, false);
};